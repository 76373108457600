import * as serializers from '@/constants/serializer';
import * as comparators from '@/constants/comparators';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const linkedProperties = {
  STATUS: 'status',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.CLIENT,
        disabled: true,
        prop: 'client',
        label: 'contracts.edit.client',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        disabled: true,
        prop: 'clientType',
        label: 'contracts.edit.client_type',
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.PROJECT,
    prop: 'project',
    label: 'label.project',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.BUILDING,
    prop: 'building',
    label: 'label.building',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.UNIT,
    prop: 'unit',
    label: 'label.unit',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'contracts.edit.rent_period',
    prop: 'rentalPeriod',
    multiple: true,
    tooltip: {
      text: 'contract.period_tooltip',
      icon: 'mdi-information',
    },
    payload: [
      {
        param: 'multiple',
        from: [linkedProperties.STATUS],
        serializer: serializers.IS_RENTED,
      },
    ],
    displayConditions: {
      variables: [
        {
          from: ['isPeriod'],
          comparableValues: [true],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `isPeriod`,
    },
  },
  {
    type: schemaItemTypes.DATE,
    prop: 'salesContractDate',
    label: 'client.sales_contract_date',
    rules: [rules.REQUIRED],
    tooltip: {
      text: 'unit.sales_contract_date_tooltip',
      icon: 'mdi-information',
    },
    displayConditions: {
      variables: [
        {
          from: ['isPeriod'],
          comparableValues: [false],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `isPeriod`,
    },
  },
];

export default schema;
